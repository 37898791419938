// Generated by Framer (a417fcb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {lleGg4f83: {hover: true}};

const cycleOrder = ["lleGg4f83"];

const serializationHash = "framer-DudTh"

const variantClassNames = {lleGg4f83: "framer-v-1cn94qr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, sJOEirRKh: link ?? props.sJOEirRKh, Y82f9aimT: title ?? props.Y82f9aimT ?? "A"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Y82f9aimT, sJOEirRKh, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lleGg4f83", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={sJOEirRKh} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1cn94qr", className, classNames)} framer-1lxxneh`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lleGg4f83"} ref={ref ?? ref1} style={{"--border-bottom-width": "3px", "--border-color": "rgb(156, 117, 255)", "--border-left-width": "3px", "--border-right-width": "3px", "--border-style": "solid", "--border-top-width": "3px", backgroundColor: "rgb(156, 117, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} variants={{"lleGg4f83-hover": {"--border-bottom-width": "2px", "--border-color": "rgb(29, 17, 43)", "--border-left-width": "2px", "--border-right-width": "2px", "--border-top-width": "2px"}}} {...addPropertyOverrides({"lleGg4f83-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>A</motion.p></React.Fragment>} className={"framer-12ckcwg"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"lm52n26rs"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)"}} text={Y82f9aimT} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DudTh.framer-1lxxneh, .framer-DudTh .framer-1lxxneh { display: block; }", ".framer-DudTh.framer-1cn94qr { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 37px; justify-content: center; padding: 10px; position: relative; text-decoration: none; width: 37px; }", ".framer-DudTh .framer-12ckcwg { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DudTh.framer-1cn94qr { gap: 0px; } .framer-DudTh.framer-1cn94qr > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-DudTh.framer-1cn94qr > :first-child { margin-top: 0px; } .framer-DudTh.framer-1cn94qr > :last-child { margin-bottom: 0px; } }", ".framer-DudTh.framer-v-1cn94qr.hover.framer-1cn94qr { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 37px); }", ".framer-DudTh[data-border=\"true\"]::after, .framer-DudTh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 37
 * @framerIntrinsicWidth 37
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"potjhF5sp":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Y82f9aimT":"title","sJOEirRKh":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercYSrk6GnH: React.ComponentType<Props> = withCSS(Component, css, "framer-DudTh") as typeof Component;
export default FramercYSrk6GnH;

FramercYSrk6GnH.displayName = "table-content-button";

FramercYSrk6GnH.defaultProps = {height: 37, width: 37};

addPropertyControls(FramercYSrk6GnH, {Y82f9aimT: {defaultValue: "A", displayTextArea: false, title: "Title", type: ControlType.String}, sJOEirRKh: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramercYSrk6GnH, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/DpPBYI0sL4fYLgAkX8KXOPVt7c.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/4RAEQdEOrcnDkhHiiCbJOw92Lk.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/1K3W8DizY3v4emK8Mb08YHxTbs.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/tUSCtfYVM1I1IchuyCwz9gDdQ.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/VgYFWiwsAC5OYxAycRXXvhze58.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/DXD0Q7LSl7HEvDzucnyLnGBHM.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/GIryZETIX4IFypco5pYZONKhJIo.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})